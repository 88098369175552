import { Injectable } from '@angular/core';
import { SmartTableData } from '../data/smart-table';
import { HttpClient } from '@angular/common/http';
import { format } from 'date-fns';
import { environment } from '../../../environments/environment';

@Injectable()
export class SmartTableService extends SmartTableData {
  
  constructor(private http: HttpClient){
    super();
  }
  
  getData(pestab: string, pdt_ini: Date, pdt_fim: Date, pnmarq: number, pmesano: number) {

    const formattedPdtIni = format(pdt_ini, "yyyy-MM-dd 00:00:00");
    const formattedPdtFim = format(pdt_fim, "yyyy-MM-dd 23:59:59");

    return this.http.post<any>(environment.URLAPI + "/processos", {
      pestab,
      pdt_ini: formattedPdtIni,
      pdt_fim: formattedPdtFim,
      pgr_ini: "01",
      pgr_fim: "99",
      pnmarq: pnmarq,
      pmesano: pmesano,
    })
  }


}
