import { fromEvent, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Injectable} from '@angular/core';

@Injectable()
export class SessionObserver {

  constructor() {
  }

  /* Observe a specific entry in SessionStorage */
    observe(key: string): Observable<StorageEvent> {
        // Observe the window for "storage" events
        return fromEvent<StorageEvent>(window, 'storage')
        .pipe(
            filter((e: StorageEvent) => e.key === key),
            filter((e: StorageEvent) => e.newValue !== e.oldValue),
        );
    }
}
