import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NbAuthJWTToken, NbAuthService, NbTokenService, nbTokensFactory } from '@nebular/auth';
import { HttpClient } from '@angular/common/http';
import jwt_decode from 'jwt-decode';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  themes = [];

  currentTheme = '';

  // userMenu = [ { title: 'Sair'} ];

  userMenu = [
    // { title: 'Profile', icon: 'fa fa-user' },
    // { title: 'Settings', icon: 'fa fa-gear' },
    { title: 'Desconectar', icon: 'fa fa-sign-out' }];
  router: any;


  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private authService: NbAuthService,
              private http: HttpClient,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              private nbTokenService: NbTokenService) {
  
              this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
                if(token.isValid()) {
                    this.user = jwt_decode(token.getValue())

                    this.http.post<any>(environment.URLAPI + "/users/clientes", 
                      {
                        pusuario: this.user.pusuario,
                        psenha: this.user.psenha
                      }  
                    ).subscribe((data) => {
                      data.forEach(element => {

                        if(this.currentTheme === '') {

                          const sessionCliente = sessionStorage.getItem("CLIENTE");

                          if(sessionCliente) {
                            this.currentTheme = sessionCliente;
                          } else {
                            this.currentTheme = element.ESTAB;
                            sessionStorage.setItem("CLIENTE", this.currentTheme);
                          }
                        }
                        this.themes.push({
                          value: element.ESTAB, 
                          name: element.NOMEE
                        })
                      });
                    })

                }
              })

              this.menuService.onItemClick()
              .subscribe((event) => {
                this.onContecxtItemSelection(event.item.title);
              });


  
  }
  
  onContecxtItemSelection(title) {
    console.log('click', title);

    if(title === 'Desconectar') {
      this.nbTokenService.clear();
      this.router.navigate(['/auth/login']);
    }

  }

  ngOnInit() {
    // this.currentTheme = this.themeService.currentTheme;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    sessionStorage.setItem("CLIENTE", themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}