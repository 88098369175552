import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { format } from 'date-fns';
import { environment } from '../../../environments/environment';
import { DetalheProcesso } from '../data/detalhe-processo';

@Injectable()
export class DetalheService extends DetalheProcesso {
  
  constructor(private http: HttpClient){
    super();
  }
  
  getDetalhe(pestab: string, pordem: string) {

    console.log(pestab)
    console.log(pordem)

    return this.http.get<any>(environment.URLAPI + `/processos/detail/${pestab}/${pordem}`)
  }

}
